import React, { useRef, useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link, withPrefix } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CAnchorNav,
  CSectTitle03,
  CFocalMedia,
  CLocalNav,
  CDefinition,
  CBenefitCard,
  CBtnList,
  CBreadCrumb,
} from '../../components/_index';
import '../../assets/_sass/page/about.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ホテルについて',
            sub: 'ABOUT',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/kv.webp',
              },
              imgSp: {
                src: '/assets/images/about/kv__sp.webp',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_about">
        <section className="u_mt50 u_mb50">
          <LWrap>
            <CAnchorNav
              exClass="p_anchor_nav"
              data={[
                {
                  title: <>街を、楽しむ</>,
                  link: {
                    href: '#enjoy_the_city',
                  },
                },
                {
                  title: <>館内について</>,
                  link: {
                    href: '#about_hall',
                  },
                },
                {
                  title: <>ホテル概要</>,
                  link: {
                    href: '#overview',
                  },
                },
                {
                  title: <>お得な会員制度</>,
                  link: {
                    href: '#benefit',
                  },
                },
                {
                  title: <>お知らせ</>,
                  link: {
                    href: '/information/',
                  },
                },
              ]}
            />
          </LWrap>
        </section>

        <section className="p_tokyo_stay">
          <CJumbotron
            size="large"
            data={{
              title: {
                main: 'CHIC TOKYO STAY',
                sub: '粋な街の、意気なおもてなし',
              },
              imgList: [
                {
                  img: {
                    src: '/assets/images/about/img_chic_tokyo_stay.png',
                  },
                },
              ],
            }}
          ></CJumbotron>
          <div className="p_tokyo_stay_lead u_tac">
            <p className="c_sectLead u_tac u_mb0_pc u_mb30_sp">
              TOKYOと東京、革新と伝統、
              <br />
              新しさと懐かしさが交差する街“日本橋”。
              <br />
              ロイヤルパークホテルは、
              <br />
              その息づかいとぬくもりが
              <br className="u_sp" />
              寄り添う街にあります。
              <br />
            </p>

            <p className="c_sectLead u_tac u_mb30">
              この街で、心からのおもてなし。
              <br />
              和魂洋才のホスピタリティで、
              <br className="u_sp" />
              あなたをお迎えいたします。
            </p>

            <p>粋なホテルの、心意気。</p>
          </div>
        </section>

        <section id="enjoy_the_city" className="u_pt100 u_pb100">
          <LWrap>
            <CSectTitle03 title="街を、楽しむ" />
            <CFocalMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_enjoy_town.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/about/img_enjoy_town__sp.png',
                    alt: '',
                  },
                  text: (
                    <>
                      東京・⽇本橋を優雅に楽しく再発見。
                      <br />
                      「安産・子授けの水天宮」をはじめ、ルームキー持参でお得な特典を受けられるサービスや、観光スポット、観光に便利なサービスをご紹介いたします。
                    </>
                  ),
                  btn: {
                    label: '街を、楽しむ',
                    link: {
                      href: '/about/enjoy_the_city/',
                    },
                    color: 'borderBlack',
                  },
                },
              ]}
            />
          </LWrap>
        </section>

        <section id="about_hall" className="u_bgGray u_pt100 u_pb100">
          <LWrap>
            <CSectTitle03 title="館内について" exClass="border_dark" />
            <p className="c_sectLead u_tac_pc">
              ホテル館内には、ショッピングアーケードやリラクゼーションサロンなど、{' '}
              <br className="u_pc" />
              ホテルライフを快適に過ごせる、様々な施設を取り揃えております。
            </p>
            <CLocalNav
              data={[
                {
                  img: {
                    src: '/assets/images/about/img_hall.png',
                    alt: '',
                  },
                  link: {
                    href: '/about/facility/garden/',
                  },
                  title: {
                    main: '日本庭園・茶室「耕雲亭」',
                  },
                },
                // {
                //   img: {
                //     src: '/assets/images/about/img_hall02.png',
                //     alt: '',
                //   },
                //   link: {
                //     href: '/about/facility/loccitane_spa/',
                //   },
                //   title: {
                //     main: (
                //       <>
                //         LA VILLA Spa
                //         <br />
                //         by L’OCCITANE Tokyo
                //       </>
                //     ),
                //   },
                // },
                {
                  img: {
                    src: '/assets/images/about/img_hall03.png',
                    alt: '',
                  },
                  link: {
                    href: '/about/facility/shop/',
                  },
                  title: {
                    main: 'ショップ',
                  },
                },
                {
                  img: {
                    src: '/assets/images/about/img_hall04.png',
                    alt: '',
                  },
                  link: {
                    href: '/about/facility/accessibility/',
                  },
                  title: {
                    main: 'バリアフリー',
                  },
                },
              ]}
            />
          </LWrap>
        </section>

        <section id="overview" className="u_pt100">
          <LWrap exClass="p_overview">
            <CSectTitle03 title="ホテル概要" />
            <CDefinition
              data={[
                {
                  title: 'ホテル名称',
                  text: (
                    <>
                      ロイヤルパークホテル
                      <br className="u_sp" />
                      （ROYAL PARK HOTEL）
                    </>
                  ),
                },
                {
                  title: '開業日',
                  text: <>1989年6月1日</>,
                },
                {
                  title: '所在地',
                  text: (
                    <>
                      <ul>
                        <li>東京都中央区日本橋蛎殻町2-1-1</li>
                        <li>
                          <Link to="/about/access/">
                            アクセス
                            <i className="icon-arrow" />
                          </Link>
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  title: '客室数',
                  text: <>419室</>,
                },
                {
                  title: 'レストラン＆バー',
                  text: (
                    <>
                      <ul>
                        <li>鉄板焼 すみだ</li>
                        <li>日本料理 源氏香／天婦羅カウンター 松風</li>
                        <li>中国料理 桂花苑</li>
                        <li>シェフズダイニング シンフォニー</li>
                        <li>ロビーラウンジ フォンテーヌ</li>
                        <li>メインバー ロイヤルスコッツ</li>
                        <li>スイーツ&ベーカリー 粋</li>
                        <li>すし処 日本橋 矢の根寿司</li>
                      </ul>
                    </>
                  ),
                },
                {
                  title: '宴会場',
                  text: (
                    <>
                      <ul className="banquet_list">
                        <li>
                          <p className="u_mb0">大宴会場</p>
                          <p className="intent">ロイヤルホール</p>
                        </li>
                        <li>
                          <p className="u_mb0">中宴会場</p>
                          <p className="intent u_mb0">
                            春海 有明 東雲 クラウンルーム
                          </p>
                          <p className="intent">瑠璃 パラッツオ</p>
                        </li>
                        <li>
                          <p className="u_mb0">小宴会場</p>
                          <p className="intent u_mb0">ローズルーム 琥珀</p>
                          <p className="intent">霞 彩 宴 ブライズルーム</p>
                        </li>
                        <li>
                          <p className="u_mb0">会議室</p>
                          <p className="intent">ミーティングルーム</p>
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  title: '館内施設',
                  text: (
                    <>
                      <div className="flex_wrap">
                        <ul>
                          <li>茶室「耕雲亭」</li>
                          <li>チャペル</li>
                          <li>美容室</li>
                          <li>写真室</li>
                          <li>フラワーショップ</li>
                        </ul>
                        <ul>
                          <li>日本庭園</li>
                          <li>神殿</li>
                          <li>理容室</li>
                          <li>衣裳室</li>
                          <li>ショップ</li>
                          <li>駐車場</li>
                        </ul>
                      </div>
                    </>
                  ),
                },
                {
                  title: '総支配人',
                  text: <>小澤 幸一</>,
                },
                {
                  title: '運営',
                  text: (
                    <>
                      <p>三菱地所ホテルズ＆リゾーツ株式会社</p>
                      <table>
                        <tr>
                          <td>
                            <Link
                              to="https://www.royalparkhotels.co.jp/about/"
                              target="_blank"
                            >
                              ロイヤルパークホテルズについて
                              <i className="icon-blank"></i>
                            </Link>
                          </td>
                          <td>
                            <Link to="/faq/">
                              よくあるご質問／お問合せ
                              <i className="icon-arrow"></i>
                            </Link>
                          </td>
                        </tr>
                      </table>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>

        <section id="benefit" className="u_pt100 u_pb100">
          <LWrap>
            <CSectTitle03 title="お得な会員制度" />
            <p className="c_sectLead">
              ザ クラブ・ロイヤルパークホテルズは、
              <br className="u_pc" />
              全国のロイヤルパークホテルズでご利用いただける入会金・年会費無料の会員制度です。
              <br />
              ポイント特典やお得な割引などのさまざまなサービスがございます。
            </p>
            <CBenefitCard
              data={[
                {
                  label: (
                    <>
                      Benefit <em>01</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>お得</em>な<br />
                      宿泊プラン
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>02</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit02.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      レストラン・<sup>※</sup>
                      <br />
                      ショップ<em>割引</em>
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>03</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit03.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>ポイント</em>
                      <br />
                      特典
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>04</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit04.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>会員ステージ</em>
                      <br />
                      制度
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>05</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit05.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>アニバーサリー</em>
                      <br />
                      特典
                    </>
                  ),
                },
                {
                  label: (
                    <>
                      Benefit <em>06</em>
                    </>
                  ),
                  img: {
                    src: '/assets/images/common/icon_benefit06.svg',
                    alt: '',
                  },
                  title: (
                    <>
                      <em>優待施設</em>
                      <br />
                      での特典
                    </>
                  ),
                },
              ]}
            />
            <ul className="c_noteList u_mt10">
              <li>対象のレストラン・ショップは会員サイトをご確認ください。</li>
            </ul>

            <CBtnList
              data={[
                {
                  label: '会員サイト',
                  color: 'bgTheme',
                  link: {
                    href: 'https://www.royalparkhotels.co.jp/the-club/',
                    blank: true,
                  },
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
